import React from 'react'
import { Link } from 'react-router-dom'
import { Navbar, Nav, Button } from 'react-bootstrap'
import { FaPlus } from 'react-icons/fa'

export default class AppNavBav extends React.Component {

  render () {
    return (
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="#home">Hyper Local</Navbar.Brand>
        <Nav className="mr-auto">
          <Nav.Item>
            <Nav.Link href="/">Home</Nav.Link>
          </Nav.Item>
        </Nav>
        <Nav className="justify-content-end" >
          <Link to={`/new`}>
            <Button variant="outline-*">
              <FaPlus />
            </Button>
          </Link>
        </Nav>
      </Navbar>
    )
  }

}