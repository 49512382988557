import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import OrgTableView from './org_module/OrgTableView.js'
import OrgEdit from './org_module/OrgEdit.js'
import OrgAdd from './org_module/OrgAdd.js'
import LoginPage from './Users/LoginPage.js'
import ClientSideAuth from './Utils/ClientSideAuth.js'

const NotFound = () => <h1>Something went wrong</h1>

export default class RoutingPage extends React.Component {
  render () {
    return (
      <Switch>
        { /* Redirect so that it confirms to REST standards for getting Orgs list which is '/orgsList' 
            Wrap all secured pages with ClientSideAuth() so that if the user has no login cookie
            set it will redirect to login page
        */ 
        }
        <Redirect exact from="/" to="/orgs" />
        <Route path="/orgs" component={ClientSideAuth(OrgTableView)} />
        <Route path="/edit/:id" component={ClientSideAuth(OrgEdit)} />
        <Route path="/new" component={ClientSideAuth(OrgAdd)} />
        <Route path="/login" component={LoginPage} /> 
        <Route component={NotFound} />
      </Switch>
    )
  }
}
