
// Get all or some objects based fetchRoute
export async function getData (url, method) {
  const response = await fetch(url, {
    method,
    headers: {'Content-Type': 'application/json'}
  })
  const resText = await response.text() 
  return JSON.parse(resText) // converts text into JSON object so that map function can be run
}

// Create and Edit Objects
export async function postData (url, method, object) {
  const response = await fetch(url, {
    method,
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(object)
  })
  const resText = await response.text() 
  return resText// JSON.parse(resText) // converts text into JSON object so that map function can be run
}

// Delete objects
export async function deleteData (url, method) {
  const response = await fetch(url, {
    method,
    headers: {'Content-Type': 'application/json'}
  })
  return await response.text() 
}

