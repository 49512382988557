import React from 'react'
import { Table } from 'react-bootstrap'
import OrgRowView from './OrgRowView.js'
import { getData, deleteData } from '../Utils/Network.js'

export default class OrgTableView extends React.Component {
  constructor () {
    super()
    this.state = { orgData: [] }
    this.deleteOrg = this.deleteOrg.bind(this)
  }

  componentDidMount () {
    this.loadData()
  }

  async loadData () {
    const result = await getData('/orgsList', 'GET')
    this.setState({ orgData: result })
  }

  async deleteOrg (orgId) {
    console.log(`delete orgId: ${orgId}`)
    const result = await deleteData(`/orgs/${orgId}`, 'DELETE')
    console.log(`deletion result: ${result}`)
    this.loadData()
  }

  render () {
    console.log(this.state.orgData)
    const orgRows = this.state.orgData.map(org => (
      <OrgRowView key={org.orgId} org={org} deleteOrg={this.deleteOrg} />
    ))
    return (
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Org Name</th>
            <th>Org Type</th>
            <th>Latitude</th>
            <th>Longitude</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          { orgRows }
        </tbody>
      </Table>
    )
  }
}
