import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import RoutingPage from './RoutingPage.js'

const element = (
  <Router>
    <RoutingPage />
  </Router>
)

ReactDOM.render(element, document.getElementById('contents'))
