import React from 'react'
import { Link } from 'react-router-dom'
import { Form, FormGroup, Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { getData, postData } from '../Utils/Network.js'

export default class OrgEdit extends React.Component {
  constructor (props) {
    super(props)
    this.state = { orgDataForEdit: {} }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.updateData = this.updateData.bind(this)
  }

  componentDidMount () {
    this.loadData()
  }

  async loadData() {
    const { match: { params: { id } } } = this.props
    const result = await getData(`/orgs/${id}`, 'GET')
    this.setState({ orgDataForEdit: result[0] })
  }

  async updateData () {
    const { orgDataForEdit } = this.state
    const result = await postData(`/orgs/${orgDataForEdit.orgId}`, 'POST', orgDataForEdit)
    console.log(result)
  }

  handleChange (e) {
    const { name, value } = e.target
    const updatedOrg = { ...this.state.orgDataForEdit, [name]: value }
    this.setState({ orgDataForEdit: updatedOrg })
    console.log(`changes: ${updatedOrg}`)
  }

  handleSubmit (e) {
    console.log('handle submit')
    e.preventDefault()
    this.updateData()
  }

  render () {
    const { match } = this.props
    console.log(match)

    // Check if orgDataForEdit is empty. If so do return null. This is to avoid a warning 
    // 'Form input is changing from non controlled to controlled' which is caused when empty state 
    // object is set for input it is considered as non controlled and when loadData() sets the state 
    // react thinks it is controlled
    if (Object.keys(this.state.orgDataForEdit).length === 0 ) {
      return null
    }
    return (
      <Form onSubmit={this.handleSubmit}>

        <Form.Group controlId="formOrgName">
          <Form.Label>Org Name</Form.Label>
          <Form.Control 
            type="input" 
            name="orgName" 
            value={this.state.orgDataForEdit.orgName} 
            onChange={this.handleChange} 
            isInvalid={null} /> {/* Set this to non null value to indicate the field has error */}
        </Form.Group>

        <FormGroup controlId="formLat">
          <Form.Label>Latitude</Form.Label>
          <Form.Control 
            type="input" 
            name="lat" 
            value={this.state.orgDataForEdit.lat} 
            onChange={this.handleChange} 
            isInvalid={null} />
        </FormGroup>

        <Form.Group controlId="formLon">
          <Form.Label>Longtitude</Form.Label>
          <Form.Control 
            type="input" 
            name="lon" 
            value={this.state.orgDataForEdit.lon} 
            onChange={this.handleChange} 
            isInvalid={null} />
        </Form.Group> 

        <ButtonToolbar> 
          <ButtonGroup className="mr-2">
            <Button variant="primary" type="submit">Submit</Button>
          </ButtonGroup>  
          <ButtonGroup className="mr-2"> 
            <Link to="/">
              <Button variant="secondary" type="button">Back</Button>
            </Link>
          </ButtonGroup>
        </ButtonToolbar>

      </Form>
    )
  }
}
