import React from 'react'
import PropTypes from 'prop-types'
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa'

const editTooltip = (
  <Tooltip id="close-tooltip" placement="top">Edit Org</Tooltip>
)

const deleteTooltip = (
  <Tooltip id="close-tooltip" placement="top">Delete Org</Tooltip>
)

export default class OrgRowView extends React.Component {

  constructor () {
    super()
    this.onDelete = this.onDelete.bind(this)
  }

  onDelete (e) {
    e.preventDefault()
    const { org } = this.props
    this.props.deleteOrg(org.orgId)
  }

  render () {
    const org = this.props.org
    return (
      <tr>
        <td>{org.orgName}</td>
        <td>{org.orgType}</td>
        <td>{org.lat}</td>
        <td>{org.lon}</td>
        <td>
          <Link to={`/edit/${org.orgId}`}>
            <OverlayTrigger delayShow={1000} overlay={editTooltip}>
              <Button variant="outline-*">
                <FaRegEdit />
              </Button>
            </OverlayTrigger>
          </Link>
          <OverlayTrigger delayShow={1000} overlay={deleteTooltip}>
            <Button variant="outline-*" onClick={this.onDelete}>
              <FaRegTrashAlt />
            </Button>
          </OverlayTrigger>
        </td>
      </tr>
    )
  }
}

OrgRowView.propTypes = {
  org: PropTypes.object
}
