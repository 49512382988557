import React from 'react'
import { Redirect } from 'react-router-dom';
import { postData } from '../Utils/Network.js'
import AppNavBar from '../Utils/AppNavBar.js'

export default function ClientSideAuth(ComponentToWrap) {

  return class ClientAuth extends React.Component {
    constructor(props) {
      super(props)
      
      this.state = {
        authenticated: true 
      }
      this.checkUser = this.checkUser.bind(this)
    }

    componentDidMount() {
      this.checkUser()
    }

    async checkUser() {
      const result = await postData('/users/checkToken', 'POST', {})
      if (result) { // result = null means user has valid token
        this.setState({
          authenticated: false
        })
      }
    }

    render() {
      const { authenticated } = this.state
      if (!authenticated) {
        console.log('ClientSideAuth - Redirect')
        return <Redirect to="/login"/>
      }
      console.log('ClientSideAuth - Success')
      return (
        <React.Fragment>
          <div style={{ margin: '30px'}}> 
            <AppNavBar /> 
            <br />
            <ComponentToWrap { ...this.props} />
          </div>
        </React.Fragment>
      )
    }

  }
}