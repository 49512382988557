import React from 'react'
import { Form, Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { postData } from '../Utils/Network.js'
import { useMediaQuery } from 'react-responsive'

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 991 })
  return isMobile ? children : null
}

export default class LoginPage extends React.Component {  

  constructor(props) {
    super(props)

    this.state = {
      emailId: '',
      password: ''
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.loginUser = this.loginUser.bind(this)

  }

  componentDidMount() {
    document.body.style.backgroundColor = '#282c34';
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = null;
  }

  async loginUser() {
    const userObject = this.state
    const result = await postData(`/users/authenticate`, 'POST', userObject)
    if (result) {
      console.log(result)
      this.props.history.push('/')
    } else {
      console.log("Login failure")
    }
  }

  handleChange(e) {
    const { name, value } = e.target
    this.setState({ ...this.state, [name]: value })
  }

  handleSubmit(e) {
    e.preventDefault()
    this.loginUser()
  }

  render() {
    return (
      <div>

        <Desktop>
          <div id='container' style={ styleSheet.centerDesktop }> 
          <h1 style={styleSheet.titleDesktop}>Hyperlocalme</h1>

          <Form style={ styleSheet.textDesktop } onSubmit={this.handleSubmit}>
            <Form.Group controlId="formEmailId">
              <Form.Label>Email</Form.Label>
              <Form.Control 
                type="input" 
                name="emailId" 
                value={this.state.emailId} 
                onChange={this.handleChange} 
                isInvalid={null} /> {/* Set this to non null value to indicate the field has error */}
            </Form.Group>

            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control 
                type="password" 
                name="password" 
                value={this.state.password} 
                onChange={this.handleChange} 
                isInvalid={null} /> {/* Set this to non null value to indicate the field has error */}
            </Form.Group>

            <ButtonToolbar> 
              <ButtonGroup className="mr-2">
                <Button variant="primary" type="submit">Submit</Button>
              </ButtonGroup>  
            </ButtonToolbar>
          </Form>
          </div>
        </Desktop>

        <Mobile>
          <div id='container' style={ styleSheet.centerMobile }> 

          <h1 style={styleSheet.titleMobile}>Hyperlocalme</h1>
          <p style={styleSheet.textMobile}>Development in progress, tune in for future updates...</p>

          </div>
        </Mobile>


      </div>
    )
  }
}

const styleSheet = {

  centerDesktop : {
    width: '300', 
    position: 'absolute', 
    left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white'
  },

  titleDesktop: { 
    fontSize: '3em',
    paddingBottom: 60,
    fontFamily: ['Helvetica Neue', 'sans-serif'],
    fontWeight: 400,
  },

  textDesktop: { 
    fontSize: '1.5em',
    paddingBottom: 100,
    fontFamily: ['Helvetica Neue', 'sans-serif'],
    fontWeight: 300,
  },

  centerMobile : {
    width: '50%', 
    position: 'absolute', 
    left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    textAlign: 'center'
  },

  titleMobile: { 
    fontSize: '5em',
    paddingBottom: 60,
    fontFamily: ['Helvetica Neue', 'sans-serif'],
    fontWeight: 'bold',
  },

  textMobile: { 
    fontSize: '2em',
    paddingBottom: 100,
    fontFamily: ['Helvetica Neue', 'sans-serif'],
    fontWeight: 400,
  },

}