import React from 'react'
import { Link } from 'react-router-dom'
import { Form, ButtonToolbar, ButtonGroup, Button } from 'react-bootstrap'
import { postData } from '../Utils/Network.js'

export default class OrgAdd extends React.Component {
  constructor (props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.createNewOrg = this.createNewOrg.bind(this)
  }

  handleSubmit (e) {
    console.log('Add - handleSubmit')
    e.preventDefault()

    this.createNewOrg()
  }

  async createNewOrg () {
    const form = document.forms.orgAdd
    const newOrg = {
      orgName: form.orgName.value,
      orgType: 'Restaurant',
      lat: form.lat.value,
      lon: form.lon.value
    }
    const result = await postData(`/orgs`, 'POST', newOrg)
    console.log(result)
  }

  render () {
    return (
      <Form name="orgAdd" onSubmit={this.handleSubmit}>
        <Form.Group controlId="formOrgName">
          <Form.Label>Org Name</Form.Label>
          <Form.Control 
            type="input" 
            name="orgName" autoFocus />
        </Form.Group>

        <Form.Group controlId="formOrgLat">
          <Form.Label>Latitude</Form.Label>
          <Form.Control 
            type="input" 
            name="lat" />
        </Form.Group>

        <Form.Group controlId="formOrgLon">
          <Form.Label>Longtitude</Form.Label>
          <Form.Control 
            type="input" 
            name="lon" />
        </Form.Group>

        <ButtonToolbar> 
          <ButtonGroup className="mr-2">
            <Button variant="primary" type="submit">Submit</Button>
          </ButtonGroup>  
          <ButtonGroup className="mr-2"> 
            <Link to="/">
              <Button variant="secondary" type="button">Back</Button>
            </Link>
          </ButtonGroup>
        </ButtonToolbar>

      </Form>
    )
  }
}